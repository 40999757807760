.verification .individual-code {
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 15%;
  width: 2.5rem;
  height: 2.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1.5rem;
  position: relative;
}

.verification .wrap-verification-code {
  display: inline-block;
  position: relative;
  display: flex;
  justify-content: space-between;
}

.verification .hidden-input {
  position: absolute;
  border: none;
  font-size: 1rem;
  text-align: center;
  background-color: transparent;
  outline: none;
}

.verification .individual-code-shadows {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  box-shadow: 0 0 0 4px rgba(58, 151, 212, 0.28);
  border-radius: 15%;
}
