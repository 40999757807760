.select-typeahead {
    background-color: #fff;
}
.select-typeahead .clear-button {
    position: absolute;
    right: 2rem;
    top: 50%;
    transform: translate(-50%, -50%);
    font-size: 1rem;
    font-weight: 300;
    opacity: 1;
}
.select-typeahead .form-control:disabled ~ .clear-button {
    opacity: 0.48;
}
.select-typeahead.no-border .form-control {
    border: none !important;
}
.select-typeahead .dropdown-menu {
    padding: 0.75rem 0;
}
.select-typeahead .dropdown-menu .dropdown-item {
    font-size: 0.875rem;
    padding: 0.438rem 1rem;
}
.select-typeahead .dropdown-menu .dropdown-item .menu-item-icon {
    opacity: 0;
    float: right;
    color: var(--primary);
}
.select-typeahead .dropdown-menu .dropdown-item.selected, .select-typeahead .dropdown-menu .dropdown-item:hover {
    background-color: rgba(0, 75, 120, 0.07);
    color: #000;
}
.select-typeahead .dropdown-menu .dropdown-item.selected .menu-item-icon, .select-typeahead .dropdown-menu .dropdown-item:hover .menu-item-icon {
    opacity: 1;
}
.select-typeahead .dropdown-menu .dropdown-item.selected .rbt-highlight-text, .select-typeahead .dropdown-menu .dropdown-item:hover .rbt-highlight-text {
    background-color: transparent;
}
.select-typeahead .dropdown-header {
    background-color: rgba(44, 44, 44, 0.07);
    color: #000;
    padding: 0.5rem 0.75rem;
}
.select-typeahead .rbt-highlight-text {
    font-size: 0.875rem;
}
.select-typeahead .form-select-multi {
    height: 2.563rem !important;
}
.select-typeahead .form-select-multi.form-control-sm {
    height: 2.063rem !important;
    padding: 0.313rem 0.813rem;
}
.select-typeahead .form-select-multi.form-control[disabled] {
    opacity: 0.48 !important;
    background-color: transparent;
}
.select-typeahead .form-select-multi.selected, .select-typeahead .dropdown-menu .dropdown-item:hover.form-select-multi {
    border-color: #004b78;
    outline: 0;
    box-shadow: none;
}
.select-typeahead .form-select-multi .rbt-input-wrapper {
    margin: auto;
}
.select-typeahead .form-select-multi .badge {
    padding: 0.25rem 1rem;
    font-weight: normal;
}
.select-typeahead .form-select-multi:read-only {
    background-color: transparent;
}
