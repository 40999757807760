#topNavbar .nav-link.active {
    /* border-bottom: 2px solid var(--primary); */
}

#topNavbar .nav-link {
    margin-bottom: 2.5rem;
    padding: 0 !important;
}

.navbar-light .navbar-toggler {
    color: #2f2d97;
    border-color: rgba(0, 0, 0, 0);
}

.navbar-label {
    font-size: 50%;
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
    background: var(--danger);
    color: #fff;
    padding: 0 0.35rem 0.1rem;
    border-radius: 4rem;
}

.bottom-navigation {
    bottom: 1rem;
    left: 0rem;
    background: #fff;
    border-radius: 21px;
    width: 91%;
    z-index: 100;
    box-shadow: 0px -21px 54px rgb(230 230 230 / 78%);
}