@font-face {
  font-family: ganganee;
  src: url("/fonts/un-ganganee.ttf");
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Poppins", sans-serif, ganganee !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f2f5fa !important;
}

p {
  margin-bottom: 0 !important;
}

.card {
  box-shadow: 0px 14px 55px rgba(0, 0, 0, 0.11);
  border: 0 !important;
  border-radius: 0 !important;
}

hr {
  border-style: dashed !important;
  border-color: var(--seondary);
}

.border-dashed {
  border-width: 1px;
  border-style: dashed !important;
  border-color: #0000001a;
}

.logo-pns {
  height: 35px !important;
  width: 35px !important;
}

.line-height-p5 {
  line-height: 0.5;
}

.line-height-p8 {
  line-height: 0.8;
}

.line-height-2 {
  line-height: 2;
}

.text-smaller {
  font-size: 70% !important;
}

.text-extra-smaller {
  font-size: 10px;
}

.btn {
  border: 0px solid transparent;
  border-radius: 0.25rem !important;
}

.shadow {
  box-shadow: 0px 14px 55px rgba(0, 0, 0, 0.11);
}

.top-left-0 {
  top: 0;
  left: 0;
}

.bg-muted {
  background-color: #efefef;
}

.form-control {
  border: 1px dashed #ced4da !important;
  border-radius: 0rem !important;
}

.form-control:focus {
  color: #2c3090 !important;
  box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
}

.modal-content {
  border: 0 !important;
  border-radius: 0.625rem !important;
}

.modal-header {
  border-bottom: 0 !important;
}

.modal-footer {
  border-top: 0 !important;
}

.remove-spinner::-webkit-inner-spin-button,
.remove-spinner::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.carousel .control-dots {
  position: initial !important;
  padding-inline-start: 0px;
  margin: 0 !important;
}

.carousel .control-dots .dot {
  background: var(--primary) !important;
}

.carousel.carousel-slider .control-arrow {
  background: rgb(255, 255, 255, 1) !important;
}

.button-icon-width {
  width: 2rem;
}

button:focus {
  outline: 0px dotted !important;
  outline: 0px auto -webkit-focus-ring-color !important;
}

.clickable {
  cursor: pointer;
}

.blue-bg {
  /* background-color: #031930; */
  background-image: url(/assets/images/bg.svg);
  background-repeat: repeat;
}

a .text-yellow {
  color: #fdb813 !important;
}

.footer {
  /* width: 100%; */
  background-color: rgb(255, 255, 255);
  color: #05203e;
  text-align: center;
}

.btn-light.focus,
.btn-light:focus {
  color: #212529;
  background-color: #e2e6ea;
  border-color: #00000000 !important;
  box-shadow: 0 0 0 0rem rgba(0, 0, 0, 0) !important;
}

a:hover {
  text-decoration: none !important;
}

:focus {
  outline: -webkit-focus-ring-color auto 0px !important;
}

.rounded-21 {
  border-radius: 21px !important;
}

.rounded-50 {
  border-radius: 50px !important;
}

.pns-card {
  background-color: #3184ea3c !important;
}

.pns-suspended-card {
  color: var(--orange);
  background-color: #fd7d144b !important;
}

.text-orange {
  color: var(--orange) !important;
}

.main-card {
  height: 40rem;
  overflow-y: auto;
  background: transparent;
  border-radius: 0;
}

.shadow-pns {
  box-shadow: 0px -6px 20px rgb(0 0 0 / 11%);
}

.onload-opacity {
  animation-name: onload;
  animation-duration: 1.5s;
  animation-iteration-count: 1;
  animation-direction: alternate;
}

.h-header {
  height: auto !important;
}

.action-btn-header {
  /* background-color: #0c1f3c; */
  background-image: url(/assets/images/bg.svg);
  background-repeat: repeat;
  position: sticky !important;
  top: 0;
  z-index: 1000;
  border-bottom: 6px solid var(--warning);
}

.secondary-header-bg-dark {
  background-image: url(/assets/images/bg.svg);
  background-repeat: repeat;
}

.hide-elem {
  display: none !important;
}

.header-btn-text-size {
  font-size: 1.5rem !important;
}

.text-pns {
  color: #13345c;
}

.text-link {
  text-decoration: underline;
  font-style: italic;
}

.text-white {
  color: #fff !important;
}

.disable-link {
  pointer-events: none;
  opacity: 0.5;
}

@keyframes onload {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.landing-logo {
  width: 229px;
  height: 140px;
}

.min-landing-logo {
  height: 2.7rem;
  width: 4.5rem;
}

.action-btn-size {
  width: 12rem !important;
}

.join-now-action-btn-size {
  min-width: 16.2rem !important;
}

.lang-drop-bg {
  background-color: #fff !important;
}

@media (max-width: 575px) {
  .mb-xs-5 {
    margin-bottom: 3rem !important;
  }
  .mt-xs-5 {
    margin-top: 3rem !important;
  }
  .mb-xs-3 {
    margin-bottom: 1rem !important;
  }
  .mt-xs-2 {
    margin-top: 0.5rem !important;
  }
}

@media (min-width: 992px) {
  .main-card {
    height: 40rem;
    background: white;
    border-radius: 21px;
  }
  .shadow-pns {
    box-shadow: 0px 14px 55px rgb(0 0 0 / 11%);
  }
  .h-header {
    height: 50% !important;
  }
  .landing-logo {
    width: 400px;
    height: 244.5px;
  }
}

@media (max-height: 767px) {
  .main-card {
    height: 31.5rem;
    background: transparent;
    border-radius: 0;
  }
}

@media (max-width: 1199px) {
  .c-px-lg-3 {
    padding-left: 1rem !important;
    padding-right: 1rem !important;
  }
  .c-mt-lg-1 {
    margin-top: 0.5rem !important;
  }
}

@media (min-width: 1199px) {
  .w-xl-75 {
    width: 75% !important;
  }
}

@media (max-width: 1199px) {
  .update-details-btn {
    margin-top: 1.2rem;
  }
}

#dropdown-basic::after {
  display: none;
}

.landing-info-card {
  min-height: 26.5rem !important;
}

.lang-drop {
  z-index: 100;
}

.form-date .input-invalid {
  border-color: #dd3444 !important;
}

.form-date .input-invalid:focus {
  border-color: #dd3444 !important;
  box-shadow: 0 0 0 0.2rem rgba(221, 52, 68, 0.25) !important;
}

.form-date .input-valid {
  border-color: #00a74b !important;
}

.form-date .input-valid:focus {
  border-color: #00a74b !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 167, 75, 0.25) !important;
}

.was-validated .form-control:invalid {
  border-color: #dd3444 !important;
}

.was-validated .form-control:invalid:focus {
  border-color: #dd3444 !important;
  box-shadow: 0 0 0 0.2rem rgba(221, 52, 68, 0.25) !important;
}

.was-validated .form-control:valid {
  border-color: #00a74b !important;
}

.was-validated .form-control:valid:focus {
  border-color: #00a74b !important;
  box-shadow: 0 0 0 0.2rem rgba(0, 167, 75, 0.25) !important;
}

.birthday-present-img-container {
  height: 2rem;
}

.birthday-present-img {
  border-radius: 0.625rem;
  height: inherit;
  width: inherit;
  object-fit: contain;
}

.family-first-logo-container {
  border-radius: 0.625rem;
  background-color: #13345d;
  height: 10rem;
  width: 100%;
}

.family-first-logo {
  border-radius: 0.625rem;
  height: inherit;
  width: inherit;
  object-fit: fill;
}

.info-card-height {
  min-height: 12rem;
}

@media (max-width: 1199px) {
  .info-card-height {
    min-height: 0rem;
  }
}

.info-card-t2-height {
  min-height: 22rem;
}

@media (max-width: 1199px) {
  .info-card-t2-height {
    min-height: 0rem;
  }
}

.confirm-password-input {
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.was-validated .confirm-password-input:invalid {
  border-color: #dd3444 !important;
}

.was-validated .confirm-password-input:invalid:focus {
  border-color: #dd3444 !important;
  box-shadow: 0 0 0 0.2rem rgba(221, 52, 68, 0.25) !important;
}

.passwords-no-match {
  background-color: #dd344526 !important;
}

@media (max-width: 991px) {
  .nd-lg-none {
    display: none !important;
  }
}

@media (min-width: 768px) {
  .nd-gt-md-none {
    display: none !important;
  }
}

@media (max-width: 767px) {
  .nd-lt-md-none {
    display: none !important;
  }
}

.disabled-bg {
  background-color: #e9ecef !important;
}

.w-landing-page-btn {
  width: 100% !important;
}

.w-sign-up-btn {
  width: 100% !important;
}

.w-login-btn {
  width: 100% !important;
}

@media (min-width: 768px) {
  .w-landing-page-btn {
    width: 50% !important;
  }

  .w-sign-up-btn {
    width: 50% !important;
  }

  .w-login-btn {
    width: 50% !important;
  }

  .lang-drop-bg {
    background-color: transparent !important;
  }
}

@media (min-width: 992px) {
  .w-sign-up-btn {
    width: 75% !important;
  }

  .w-login-btn {
    width: 100% !important;
  }
}

@media (min-width: 1201px) {
  .w-sign-up-btn {
    width: 65% !important;
  }

  .w-login-btn {
    width: 100% !important;
    font-size: 1.3rem !important;
  }

  .fs-login-btn-q {
    font-size: 1.3rem !important;
  }
}
